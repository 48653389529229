import { useMemoizedContentGetter } from 'utils/contentful'
import { PaperCarousel } from 'components/Carousels/PaperCarousel'
import { object, array, string } from 'yup'
import { richTextSchema } from 'constants/validation-types'

let contentSchema = object({
  title: object({
    textContent: object({
      json: richTextSchema,
    }),
  }),
  theme: object({
    theme: array(string()),
  }).default({ theme: ['light-blue'] }),
  carousel: object({
    carouselItemsCollection: object({ items: array(object()) }),
  }),
})

function MoreResourcesCarousel({ content }) {
  let memoizedContent = useMemoizedContentGetter(content, [
    'title',
    'carousel',
    'theme',
    'eyebrow',
    'config',
  ])
  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn(
      'MoreResourcesCarousel - INVALID CONTENT RESPONSE',
      JSON.stringify(memoizedContent)
    )
    return null
  }

  const {
    title,
    theme,
    eyebrow,
    carousel: { carouselItemsCollection },
    config,
  } = memoizedContent

  return (
    <PaperCarousel
      title={title}
      carouselItems={carouselItemsCollection?.items}
      theme={theme}
      idPrefix="home"
      eyebrow={eyebrow}
      listNameTracking={config?.jsonContent?.listNameTracking}
    />
  )
}
export { MoreResourcesCarousel }
export default MoreResourcesCarousel
